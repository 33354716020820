<template>
  <div class="item">
    <div class="centered-content">
      <v-row class="text-wrapper" align="center" justify="center">
        <div class="text-wrapper">
          <div id="name">Quinten Mortier</div>

          <VueTypewriterEffect
            :cursor="'_'"
            :strings='["Software Engineer", "Data Engineer", "Cloud Engineer", "Full Stack Developer"]'
            class="subheading"
          />
        </div>
      </v-row>

      <!-- Arrow positioned with absolute positioning -->
      <div class="scroll-arrow">
        <FeArrowDown />
      </div>
    </div>
  </div>
</template>

<script>
import VueTypewriterEffect from "vue-typewriter-effect";
import { FeArrowDown } from "@kalimahapps/vue-icons";

export default {
  name: "Contact",
  components: { VueTypewriterEffect, FeArrowDown },
};
</script>

<style>
#name {
  font-family: jetbrains-bold;
  font-size: 60px;
  color: rgb(6, 65, 90);
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  position: relative;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to the left */
}

.subheading {
  font-family: jetbrains-regular;
  font-size: 25px;
  color: rgb(6, 65, 90);
  margin-left: 0; /* Ensure it aligns directly under the name */
}

.scroll-arrow {
  position: absolute;
  bottom: 50px; /* Adjust to control the height above the bottom */
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  color: rgb(6, 65, 90);
  animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
