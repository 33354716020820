<template>
  <div class="item centered-content">
    <v-row align="center" justify="center" class="contact-wrapper">
      <!-- Contact Form -->
      <v-col cols="12" md="9" lg="9">
          <v-card-title>Contact Me</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field  variant="outlined" label="Name" required></v-text-field>
              <v-text-field  variant="outlined" label="Email" required></v-text-field>
              <v-textarea   variant="outlined" label="Message" rows="5" required></v-textarea>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="submitForm">Send</v-btn>
          </v-card-actions>
      </v-col>
    </v-row>
      <v-row class="social-links" >

        <pre class="pacman"></pre>


        <v-btn icon href="https://linkedin.com/in/yourprofile" target="_blank">
          <AkLinkedinBoxFill/>
        </v-btn>
        <v-btn icon href="https://instagram.com/yourprofile" target="_blank">
          <AkInstagramFill/>
        </v-btn>
        <v-btn icon href="https://facebook.com/yourprofile" target="_blank">
          <AnFilledFacebook/>
        </v-btn>
        <v-btn icon href="tel:+123456789">
          <AkPhone/>
        </v-btn>
        <v-btn icon href="mailto:your-email@example.com">
          <AnOutlinedMail/>
        </v-btn>
      </v-row>


  </div>
</template>

<script>

import { AkLinkedinBoxFill } from '@kalimahapps/vue-icons';
import { AnFilledFacebook } from '@kalimahapps/vue-icons';
import { AkInstagramFill } from '@kalimahapps/vue-icons';
import { AkPhone } from '@kalimahapps/vue-icons';
import { AnOutlinedMail } from '@kalimahapps/vue-icons';

fetch('./pacman.txt')
.then(response => response.text())
.then(text => {
  document.querySelector('.pacman').textContent = text;
  console.log(text);
});


export default {
  name: "Contact",
  methods: {
    submitForm() {
      // Logic to handle form submission
    },
  },
  components: { AkLinkedinBoxFill, AnFilledFacebook, AkInstagramFill, AkPhone, AnOutlinedMail },
};
</script>

<style>
.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.contact-wrapper {
  max-width: 600px;
  width: 100%;
}

.contact-card {
  padding: 20px;
}

.social-links {

    margin:10px;
    /* text-align: center; */
    display: flex;
    vertical-align:middle;
    justify-content: center;
    align-items: center;
    margin-right: 130px;
}

.social-links .v-btn {
  margin: 0 10px;
  color: rgb(6, 65, 90); /* Matches your site color */
}

.pacman {
    font-family: monospace;
    line-height: 1.1;
    font-size: 9px;
    color: rgb(6, 65, 90);
    white-space: pre-wrap;
    margin-right: -50px;
  }
</style>
