<template>
    <div class="item">
        Portfolio
    </div>
  </template>
  
  <script>

  export default {
    name: "Portfolio"
  };

  </script>
  
  <style>

  </style>
  