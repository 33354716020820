<template>
  <div class="item"> <!-- Add d-flex and align-start classes -->
      <v-row  align="center"
      justify="center">
          <v-col cols="12" md="8" class="px-16">
              <h3>Work Experience</h3>
              <table id="table">
                <tr>
                  <td class="td-first">Belga</td>
                  <td class="td-middle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed quam quam. Praesent in tincidunt risus, sit amet eleifend dolor.</td>
                  <td class="td-year">2023 - 2024</td>
                </tr>

                <tr>
                  <td class="td-first">Axxes</td>
                  <td class="td-middle">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed quam quam. Praesent in tincidunt risus, sit amet eleifend dolor.</td>
                  <td class="td-year">2023 - 2024</td>
                </tr>
              </table>

              <h3 class="pt-16">Education</h3>
              <table id="table">
                <tr>
                  <td class="td-first">KU Leuven</td>
                  <td class="td-middle"> Master of Science: Computer Science</td>
                  <td class="td-year">2023 - 2024</td>
                </tr>

                <tr>
                  <td class="td-first">Karel De Grote</td>
                  <td class="td-middle">Bachelor: Applied Computer Science</td>
                  <td class="td-year">2023 - 2024</td>
                </tr>
              </table>

             
              <v-row justify="center" align="center" class="pt-16 resume">
               full Resume
                <FeArrowRight class="icon"/>
              </v-row>

          </v-col>
          <v-col cols="12" md="4" class="px-16">
                      <pre class="profilepic"></pre>
                      <h3>About Myself</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sed quam quam. Praesent in tincidunt risus, sit amet eleifend dolor. Pellentesque accumsan dapibus leo vitae pretium. Proin vehicula pharetra fermentum. Nunc ut nulla rutrum neque tincidunt elementum ut ac orci.</br> Morbi eget massa varius, interdum ante eu, tempor dolor. Cras scelerisque scelerisque leo, at blandit orci faucibus sit amet. Nunc pulvinar posuere aliquet. Aenean et ipsum tortor. Sed posuere sodales venenatis. Donec eget tincidunt odio.</p>
          </v-col>

      </v-row>
  </div>
</template>

<script>

import { FeArrowRight } from '@kalimahapps/vue-icons';

fetch('./profilepicture.txt')
.then(response => response.text())
.then(text => {
  document.querySelector('.profilepic').textContent = text;
});


export default {
name: "CV",
components: { FeArrowRight },
};

</script>

<style scoped>
.item {
  display: flex;
}

.profilepic {
    font-family: monospace;
    line-height: 1.1;
    font-size: 4px;
    color: rgb(6, 65, 90);
    white-space: pre-wrap;
    padding-right: 30px;
  }

  h3 {
    font-family: jetbrains-regular;
    font-size: 20px;
    color: rgb(6, 65, 90);
    padding-top: 20px;
    padding-bottom: 20px;
  }

  p {
  font-family: jetbrains-regular;
  font-size: 12px;
  color: rgb(6, 65, 90);
  width: 100%;              /* Set width to control line length */
  text-align: justify;       /* Justify text for a square-like look */
  line-height:2;          /* Adjust for vertical balance */
}

  /* Remove default border from table and cells */
  table {
    border-collapse: collapse;
    width: 100%;
  }

  /* Add bottom border only to table rows */
  tr {
    border-bottom: 3px dashed ;
    border-top: 3px dashed;
  }

  th, td {
    font-family: jetbrains-regular;
    padding: 8px;
  }

  a {
    font-family: jetbrains-regular;
    font-size: 20px;
    color: rgb(6, 65, 90);
    text-decoration: none;
  }

  .td-year {
    width: 130px;
  }

  .td-first {
    text-align: center;
    width: 200px;
  }

  .td-middle {
    text-align: left;
  }

  .resume { 
    font-family: jetbrains-regular;
    font-size: 20px;
    color: rgb(6, 65, 90);
    margin-top: 20px;
  }

  .resume:hover {
    color: rgb(6, 65, 90);
    text-decoration: underline;
    cursor: pointer;
  }

  .icon {
    font-size: 30px;
    color: rgb(6, 65, 90);
    margin-left: 10px;
  }


  .cursor{
    position: relative;
    width: 24em;
    margin: 0 auto;
    border-right: 2px solid rgba(255,255,255,.75);
    font-size: 30px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    transform: translateY(-50%);    
}
/* Animation */
.typewriter-animation {
  animation: 
    typewriter 5s steps(50) 1s 1 normal both, 
    blinkingCursor 500ms steps(50) infinite normal;
}
@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}
@keyframes blinkingCursor{
  from { border-right-color: rgba(255,255,255,.75); }
  to { border-right-color: transparent; }
}
</style>
