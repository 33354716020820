<template>
  <div id="app">
    <title>Quinten Mortier</title>
    <nav>
      <ul>
        <li>
          <a
            :class="{ active: currentSection === 'Landing' }"
            @click.prevent="scrollToSection('Landing')"
          >Landing</a>
        </li>
        <li>
          <a
            :class="{ active: currentSection === 'About' }"
            @click.prevent="scrollToSection('About')"
          >About</a>
        </li>
        <li>
          <a
            :class="{ active: currentSection === 'Portfolio' }"
            @click.prevent="scrollToSection('Portfolio')"
          >Portfolio</a>
        </li>
        <li>
          <a
            :class="{ active: currentSection === 'Contact' }"
            @click.prevent="scrollToSection('Contact')"
          >Contact</a>
        </li>
      </ul>
    </nav>

    <vue-scroll-snap :fullscreen="true" class="body" @scroll="handleScroll">
      <Landing id="Landing" />
      <About id="About" />
      <Portfolio id="Portfolio" />
      <Contact id="Contact" />
    </vue-scroll-snap>
  </div>
</template>

<script>
import VueScrollSnap from "vue-scroll-snap";
import About from "./components/About.vue";
import Contact from "./components/Contact.vue";
import Landing from "./components/Landing.vue";
import Portfolio from "./components/Portfolio.vue";

export default {
  name: "App",
  components: { VueScrollSnap, About, Contact, Landing, Portfolio },
  data() {
    return {
      currentSection: "Landing", // Default to first section
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.currentSection = sectionId; // Update the current section on click
      }
    },
    handleScroll(event) {
      const sections = ["Landing", "About", "Portfolio", "Contact"];
      sections.forEach((sectionId) => {
        const section = document.getElementById(sectionId);
        const rect = section.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          this.currentSection = sectionId;
        }
      });
    },
  },
};
</script>

<style>
/* Same styles as before */

@font-face {
    font-family: jetbrains-light;
    src: url('~@/assets/fonts/JetBrainsMono-Light.ttf');
}

@font-face {
    font-family: jetbrains-regular;
    src: url('~@/assets/fonts/JetBrainsMono-Regular.ttf');
}

@font-face {
    font-family: jetbrains-bold;
    src: url('~@/assets/fonts/JetBrainsMono-Bold.ttf');
}

@font-face {
    font-family: jetbrains-italic;
    src: url('~@/assets/fonts/JetBrainsMono-Italic.ttf');
}




.body{
  background-color: #f0f4ff;
  font-family: jetbrains-regular;

}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  font-family: jetbrains-regular;
  position: fixed;
  top: 0;
  padding: 70px;
  z-index: 10;
}

nav ul {
  text-align: left;
  list-style: none;
  gap: 15px;
  margin: 0;
  padding: 0;
}

nav ul li a {
  text-decoration: none;
  font-size: 18px;
}

nav ul li a.active {
  text-decoration: underline; /* Underline active link */
  font-weight: bold; /* Optional: make it bold for extra emphasis */
  color: #06415a; /* Optional: change color for active link */
}

nav ul li a:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>